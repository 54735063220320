<template>
  <div v-if="show" class="image-overlay">
    <transition name="loadfade">
      <Loading v-if="isLoading" class="centered-loader" />
    </transition>

    <div class="overlay-content">
      <button class="close-btn" aria-label="Close image viewer" @click="closeViewer">
        <i class="fas fa-times"></i>
      </button>

      <div class="image-container" @wheel="handleZoom" ref="imageContainer">
        <div 
          class="image-wrapper" 
          :style="transformStyle"
          @mousedown="startPan"
          @touchstart="startPan"
        >
          <img 
            :src="image.SignedUrl" 
            :style="{ 
              filter: `brightness(${brightness}%) contrast(${contrast}%)`,
              width: width ? `${width}px` : 'auto',
              height: height ? `${height}px` : 'auto'
            }"
            alt="Full size image" 
            draggable="false"
          />

          <img v-if="showAVR" 
            :src="AVR_URL" 
            :style="{ 
              filter: `brightness(${brightness}%) contrast(${contrast}%)`,
              width: width ? `${width}px` : 'auto',
              height: height ? `${height}px` : 'auto'
            }"
            class="overlay-image avr-overlay"
            draggable="false" />

          <img v-if="showVCDR" 
            :src="VCDR_URL" 
            :style="{ 
              filter: `brightness(${brightness}%) contrast(${contrast}%)`,
              width: width ? `${width}px` : 'auto',
              height: height ? `${height}px` : 'auto'
            }"
            class="overlay-image vcdr-overlay"
            draggable="false" />
        </div>
      </div>

      <div class="controls-wrapper">
        <div class="image-controls">
          <div class="control-group">
            <span class="control-label">Brightness</span>
            <input type="range" v-model="brightness" min="0" max="200" step="10">
            <span class="control-value">{{brightness}}%</span>
          </div>
          <div class="control-group">
            <span class="control-label">Contrast</span>
            <input type="range" v-model="contrast" min="0" max="200" step="10">
            <span class="control-value">{{contrast}}%</span>
          </div>
          <div class="control-group">
            <span class="control-label">AVR</span>
            <label class="toggle-switch">
              <input type="checkbox" v-model="showAVR">
              <span class="toggle-slider"></span>
            </label>
          </div>
          <div class="control-group" v-if="patientUuid && sessionUuid">
            <span class="control-label">VCDR</span>
            <label class="toggle-switch">
              <input type="checkbox" v-model="showVCDR">
              <span class="toggle-slider"></span>
            </label>
          </div>

          <div class="control-group" v-if="showVCDR && VCDR_value.vcdr">
            <span class="control-label">VCDR Value</span>
            <span class="control-value highlight">{{ Number(VCDR_value.vcdr).toFixed(2) }}</span>
          </div>

          <div class="control-group" v-if="showVCDR && VCDR_value.hcdr">
            <span class="control-label">HCDR Value</span>
            <span class="control-value highlight">{{ Number(VCDR_value.hcdr).toFixed(2) }}</span>
          </div>

          <div class="control-group" v-if="showVCDR && VCDR_value.cataract_label">
            <span class="control-label">Cataract Label</span>
            <span class="control-value highlight">{{ VCDR_value.cataract_label }}</span>
          </div>
        </div>

        <div class="zoom-controls">
          <button class="zoom-btn" @click="zoomIn" aria-label="Zoom in">
            <i class="fas fa-search-plus"></i>
          </button>
          <button class="zoom-btn" @click="zoomOut" aria-label="Zoom out">
            <i class="fas fa-search-minus"></i>
          </button>
          <button class="zoom-btn" @click="resetZoom" aria-label="Reset zoom">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rww_segment, vcdr_process } from '@/services/imageProcessServices';
import Loading from './Loading.vue';

export default {
  name: 'ImageViewer',
  components: {
    Loading
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    patientUuid: {
      type: String,
    },
    sessionUuid: {
      type: String,
    },
  },
  data() {
    return {
      brightness: 100,
      contrast: 100,
      showAVR: false,
      AVR_URL: '',
      showVCDR: false,
      VCDR_URL: '',
      VCDR_value: {},
      processedImageUrl: null,
      isLoading: false,
      scale: 1,
      translateX: 0,
      translateY: 0,
      isPanning: false,
      lastX: 0,
      lastY: 0,
    }
  },
  computed: {
    transformStyle() {
      return {
        transform: `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`,
        transition: this.isPanning ? 'none' : 'transform 0.2s ease-out'
      };
    }
  },
  watch: {
    async showAVR(newValue) {
      if (newValue) {
        try {
          if(!this.AVR_URL){
            this.isLoading = true
            const response = await rww_segment(this.image.MediaUuid)
            this.AVR_URL = response.image_url ? response.image_url : ''
            this.isLoading = false
          }
        } catch (error) {
          this.isLoading = false
          this.$toast.error('Error processing AVR');
          console.error('Error processing AVR:', error);
          alert('Error processing AVR:', error);
        }
      }
    },
    async showVCDR(newValue) {
      if (newValue) {
        try {
          if(!this.VCDR_URL){
            if(this.patientUuid && this.sessionUuid){
              this.isLoading = true
              const response = await vcdr_process(this.image.MediaUuid, this.patientUuid, this.sessionUuid)
              this.VCDR_URL = response.image_url ? response.image_url : ''
              this.VCDR_value = response? response : ''
              this.isLoading = false
            }
          }
        } catch (error) {
          this.isLoading = false
          this.$toast.error('Error processing VCDR');
          console.error('Error processing VCDR_URL:', error);
          alert('Error processing VCDR:', error);
        }
      }
    },
    show(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('mousemove', this.handlePan);
    window.addEventListener('mouseup', this.endPan);
    window.addEventListener('touchmove', this.handlePan);
    window.addEventListener('touchend', this.endPan);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('mousemove', this.handlePan);
    window.removeEventListener('mouseup', this.endPan);
    window.removeEventListener('touchmove', this.handlePan);
    window.removeEventListener('touchend', this.endPan);
    document.body.style.overflow = '';
    this.reset();
  },
  methods: {
    closeViewer() {
      this.reset();
      this.$emit('update:show', false);
    },
    reset(){
      this.AVR_URL = '';
      this.VCDR_URL = '';
      this.VCDR_value = {};
      this.showAVR = false;
      this.showVCDR = false;
      this.brightness = 100;
      this.contrast = 100;
      this.resetZoom();
    },
    resetZoom() {
      this.scale = 1;
      this.translateX = 0;
      this.translateY = 0;
    },
    zoomIn() {
      if (this.scale < 5) {
        this.scale += 0.25;
      }
    },
    zoomOut() {
      if (this.scale > 0.5) {
        this.scale -= 0.25;
      }
    },
    handleZoom(event) {
      event.preventDefault();
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    startPan(event) {
      event.preventDefault();
      this.isPanning = true;
      this.lastX = event.clientX || (event.touches && event.touches[0].clientX);
      this.lastY = event.clientY || (event.touches && event.touches[0].clientY);
    },
    handlePan(event) {
      if (!this.isPanning) return;
      
      const clientX = event.clientX || (event.touches && event.touches[0].clientX);
      const clientY = event.clientY || (event.touches && event.touches[0].clientY);
      
      if (!clientX || !clientY) return;
      
      const deltaX = clientX - this.lastX;
      const deltaY = clientY - this.lastY;
      
      this.translateX += deltaX;
      this.translateY += deltaY;
      
      this.lastX = clientX;
      this.lastY = clientY;
    },
    endPan() {
      this.isPanning = false;
    },
    handleKeyDown(event) {
      if (!this.show) return;
      
      switch(event.key) {
        case 'Escape':
          this.closeViewer();
          break;
        case '+':
          this.zoomIn();
          break;
        case '-':
          this.zoomOut();
          break;
        case '0':
          this.resetZoom();
          break;
        case 'ArrowUp':
          this.translateY += 20;
          break;
        case 'ArrowDown':
          this.translateY -= 20;
          break;
        case 'ArrowLeft':
          this.translateX += 20;
          break;
        case 'ArrowRight':
          this.translateX -= 20;
          break;
      }
    }
  }
}
</script>

<style scoped>
.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.92);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  touch-action: none;
}

.overlay-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 95vw;
  max-height: 95vh;
}

.image-container {
  position: relative;
  width: 100%;
  height: calc(100% - 120px); /* Leave space for controls */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper {
  position: relative;
  transform-origin: center center;
  will-change: transform;
  cursor: grab;
}

.image-wrapper:active {
  cursor: grabbing;
}

.overlay-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  user-select: none;
  pointer-events: none; /* Prevent image from capturing pointer events */
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 10;
}

.close-btn:hover {
  background: rgba(255, 0, 0, 0.7);
  transform: scale(1.1);
}

.controls-wrapper {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.image-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 15px 20px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
}

.zoom-controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.zoom-btn {
  background: rgba(255, 255, 255, 0.15);
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
}

.zoom-btn:hover {
  background: rgba(255, 255, 255, 0.3);
}

.control-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.control-label {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
}

.control-value {
  color: white;
  font-size: 0.8rem;
}

.control-value.highlight {
  font-size: 1rem;
  color: #4CAF50;
  font-weight: bold;
}

input[type="range"] {
  width: 150px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2196F3;
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2196F3;
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #2196F3;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.centered-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.image-container {
  position: relative;
  width: fit-content;
  max-width: 100%;
}

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avr-overlay {
  opacity: 1;
  z-index: 1;
  object-fit: cover;
}

.vcdr-overlay {
  opacity: 1;
  z-index: 2;
  object-fit: contain;
  box-sizing: border-box;
}

.loadfade-enter-active,
.loadfade-leave-active {
  transition: opacity 0.3s;
}

.loadfade-enter-from,
.loadfade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .image-controls {
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    padding: 15px;
  }

  .control-group {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  input[type="range"] {
    width: 40%;
  }

  .control-label {
    width: 80px;
    text-align: left;
  }

  .vcdr-overlay {
    object-position: center;
  }
  
  .zoom-controls {
    bottom: 10px;
  }
  
  .close-btn {
    top: 10px;
    right: 10px;
  }
}
</style>
